.App {
/*  text-align: center;*/
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-height{
min-height: 90vh;
position: relative;
}

.footer-styling{
  background-color: rgb(248,249,250);
}

.navigation-height{
  height: 50px;
}

.navbar-nav > a {
  text-decoration: none;
  color: #6c757d!important;
}
.header--image::before{
  /*background: rgba(0,0,0,0.1);*/
    content: " ";
    width: 100%;
    height: 180px;
    position: absolute;
}

.light-toggle{
  background-color: rgb(248,249,250);
}

.react-pdf__Document > .react-pdf__Page__textContent, .react-pdf__Page__svg, .react-pdf__Page, .react-pdf__Page__textContent{
  max-width: 100%;
}

.react-pdf__Page, .react-pdf__Page__svg{
  display: flex !important;
}

.react-pdf__Page__textContent{
  max-width: 100% !important;
  width: 100% !important;
}

.news-container{
  /*max-width: 100% !important;*/
  width: 100% !important;
  overflow-x: hidden;
}

.offcanvas-end{
  width: 100%;
  border: none;
}

.carousel, .carousel-inner, .img-carousel{
  max-height: 400px;
}

.img-carousel{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.project-link{
color: #03a75d;
}

.navbar-nav > a:hover{
  text-decoration: underline;
}
